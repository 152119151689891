import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function getCollect(obj) {
  return request({
    url: '/goods/goodsList',
    method: 'get',
    params: obj,
  })
}
export function getCollectType(obj) {
  return request({
    url: '/goods/goodsCategoryList',
    method: 'get',
    params: obj,
  })
}