//src/utils/request.js
import axios from 'axios'
import router from '../router/index.js'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import { Toast } from "@nutui/nutui";


// 创建 axios 实例
const service = axios.create({
  baseURL: 'https://api.autostay.cn/api', //https://www.autostay.cn/api
  timeout: 5000, // request timeout
})

//  请求拦截器
service.interceptors.request.use(
  (config) => {
    //config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    // config.headers['Accept'] = 'application/json'
    // config.headers['api-version'] = 615
    let token = localStorage.getItem('app_token')
    if (token) {
      // 如果token不为null，否则传token给后台
      config.headers['token'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response
    //届时根据后端返回success或者code值判断
    if (res.data.code == 505) {
      localStorage.removeItem("app_token");
      router.push('/login')
      Toast.text(res.data.msg)
      return
    } else {
      return res.data
    }
  },
  (error) => {
    //响应错误
    return Promise.reject(error)
  }
)

export default service