<template>
  <div class="index">
    <!-- 搜索 -->
    <div class="indexSearch">
      <nut-searchbar
        @click="goSearch()"
        :disabled="true"
        v-model="searchValue"
        placeholder="请输入搜索内容"
      ></nut-searchbar>
      <img src="../assets/search.png" alt="" />
    </div>
    <!-- 轮播 -->
    <div class="indexSwiper">
      <nut-swiper
        :init-page="page"
        :pagination-visible="false"
        pagination-color="#426543"
        auto-play="3000"
        @change="changePage"
      >
        <nut-swiper-item v-for="item in swiperData" :key="item.id">
          <img :src="item.image" alt="" />
        </nut-swiper-item>
      </nut-swiper>
      <div class="indexPageination">
        <div
          v-for="(item, index) in swiperData"
          :key="index + 'a'"
          :class="currentPageIndex == index ? 'active' : ''"
        ></div>
      </div>
    </div>
    <!-- 公告 -->
    <div class="indexNotice" @click="goNotice()">
      <img src="../assets/notice.png" alt="" />
      <nut-swiper
        :init-page="page"
        :pagination-visible="false"
        direction="vertical"
        auto-play="3000"
        height="20"
        :touchable="false"
        :is-preventDefault="false"
        :is-stopPropagation="false"
        style="height: 20px"
      >
        <nut-swiper-item v-for="item in noticeData" :key="item.id">
          <p>{{ item.title }}</p>
        </nut-swiper-item>
      </nut-swiper>
    </div>
    <!-- 藏品 -->
    <div class="goods">
      <div class="goodsType" v-for="(item, index) in goodsData" :key="item.id">
        <div class="goodsTitle">
          <h1>{{ item.name }}</h1>
          <div class="ckgd" @click="goCollect(item, index)">
            查看更多
            <img src="../assets/ckgd.png" alt="" />
          </div>
        </div>
        <figure
          v-for="items in item.goods"
          @click="goDetail(items)"
          :key="items.id"
        >
          <div class="goodsImg">
            <img :src="items.image" alt="" class="goodsLogo" />
            <img
              src="../assets/sq.png"
              alt=""
              class="goodsSq"
              v-if="items.status == 2"
            />
            <div class="goodsTs" v-if="items.status == 3">
              <div class="left">
                <img src="../assets/rili.png" alt="" />{{ items.status_text }}
              </div>
            </div>
            <div class="goodsDjs" v-if="items.status == 4">
              <div class="left">
                <img src="../assets/djs.png" alt="" />
                倒计时
              </div>
              <div class="right">{{ items.djsTime }}</div>
            </div>
            <div class="goodsQgz" v-if="items.status == 1">
              <img src="../assets/qgz.png" alt="" />
            </div>
          </div>
          <figcaption>
            <div class="goodsInfo">
              <div>{{ items.name }}</div>
              <p><span>￥</span>{{ items.price }}</p>
            </div>
            <div class="goodsAuthor">
              <div>
                <img :src="items.author_image" alt="" />
                <span>{{ items.author_name }}</span>
              </div>
              <p>限量{{ items.stock }}份</p>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
    <!-- 积分商城 -->
    <div class="scoreShop">
      <div class="scoreTitle">
        <h1>积分商城</h1>
        <div class="ckgd" @click="goScore()">
          查看更多
          <img src="../assets/ckgd.png" alt="" />
        </div>
      </div>
      <div class="scoreList">
        <div class="scoreItem" @click="goDetail(item)" v-for="item in scoreData" :key="item.id">
          <div class="scoreItem-top">
            <img :src="item.image" class="scoreItem-topImg" alt="" />
          </div>
          <div class="scoreItem-bot">
            <div class="scoreItem-title">
              <div>{{ item.name }}</div>
            </div>
            <div class="scoreItem-author">
              <div class="left">
                <img :src="item.author_image" alt="" />
                <div>{{ item.author_name }}</div>
              </div>
              <div class="right">{{ item.price }}积分</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <navBar></navBar>
  </div>
</template>
<script>
import { reactive, toRefs, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import navBar from "../components/nav.vue";
import {
  getSwiper,
  getNotice,
  getGoods,
  getSearch,
  getScore,
} from "../api/index.js";
export default {
  components: { navBar },
  data() {
    return {
      swiperData: [],
      currentPageIndex: 0,
      noticeData: [],
      goodsData: [],
      pageIndex: 0,
      scoreData: [],
    };
  },
  activated() {},
  setup() {
    const $router = useRouter();
    const state = reactive({
      page: 0,
      timeOut: "",
      searchValue: "",
    });
    const goNotice = () => {
      $router.push({
        path: "/notice",
      });
    };
    onUnmounted(() => {
      clearInterval(state.timeOut);
      state.timeOut = "";
    });
    return { ...toRefs(state), goNotice };
  },

  mounted() {
    this.getSwiperData();
    this.getNoticeData();
    this.getGoodsData();
    this.getScoreData();
  },
  methods: {
    getScoreData() {
      let params = {
        page: 1,
        pagesize: 2,
      };
      getScore(params).then((res) => {
        this.scoreData = res.data.data;
      });
    },
    goScore() {
      this.$router.push("/scoreShop");
    },
    goCollect(item, index) {
      console.log(item);
      this.$router.push({
        path: "/collection",
        query: {
          goods_category_id: item.id,
          name: item.name,
          index: index,
        },
      });
    },
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {
          id: item.id,
        },
      });
    },
    goSearch() {
      this.$router.push({
        path: "/search",
      });
    },
    changePage(index) {
      this.currentPageIndex = index;
    },
    getSwiperData() {
      let params = {};
      getSwiper(params).then((res) => {
        this.swiperData = res.data;
      });
    },
    getNoticeData() {
      let params = {};
      getNotice(params).then((res) => {
        this.noticeData = res.data;
      });
    },
    getGoodsData() {
      let params = {};
      getGoods(params).then((res) => {
        this.goodsData = res.data;
        this.timeOut = setInterval(() => {
          for (let i in this.goodsData) {
            for (let y in this.goodsData[i].goods) {
              if (this.goodsData[i].goods[y].status == 4) {
                if (this.goodsData[i].goods[y].diff_time <= 0) {
                  this.goodsData[i].goods[y].status = 1;
                } else {
                  let second_time = parseInt(
                    this.goodsData[i].goods[y].diff_time
                  );
                  let time = second_time + "秒";
                  if (parseInt(second_time) > 60) {
                    var second = parseInt(second_time) % 60;
                    var min = parseInt(second_time / 60);
                    time = min + "分" + second + "秒";

                    if (min > 60) {
                      min = parseInt(second_time / 60) % 60;
                      var hour = parseInt(parseInt(second_time / 60) / 60);
                      time = hour + "小时" + min + "分" + second + "秒";

                      if (hour > 24) {
                        hour = parseInt(parseInt(second_time / 60) / 60) % 24;
                        var day = parseInt(
                          parseInt(parseInt(second_time / 60) / 60) / 24
                        );
                        time = day + "天" + hour + "小时" + min + "分";
                      }
                    }
                  }
                  this.goodsData[i].goods[y].djsTime = time;
                  this.goodsData[i].goods[y].diff_time -= 1;
                }
              }
            }
          }
        }, 1000);
      });
    },
  },
};
</script>

<style lang="scss">
.index {
  padding: 15px;
  padding-bottom: 65px;
  .indexSearch {
    position: relative;
    height: 35px;
    margin-bottom: 15px;
    .nut-searchbar {
      padding: 0;
      background-color: unset;
      .nut-searchbar__search-input {
        height: 35px;
        box-shadow: unset;
        background-color: #e9eaec;
        .nut-searchbar__input-inner {
          font-size: 12px;
          input {
            text-indent: 21px;
            font-size: 12px;
          }
        }
      }
    }
    img {
      position: absolute;
      left: 15px;
      width: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .indexSwiper {
    width: 100%;
    .nut-swiper {
      border-radius: 10px;
    }
    .nut-swiper-item {
      height: 128px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .indexPageination {
      height: 7px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 5px;
      div {
        width: 6px;
        height: 6px;
        background: #333333;
        opacity: 0.3;
        border-radius: 50%;
        margin-right: 7px;
      }
      div.active {
        width: 7px;
        height: 7px;
        opacity: 1;
      }
      div:last-child {
        margin-right: 0;
      }
    }
  }
  .indexNotice {
    margin-top: 15px;
    display: flex;
    align-items: center;
    height: 20px;
    width: 100%;
    img {
      width: 14px;
      flex-shrink: 0;
      margin-right: 6px;
    }
    // .nut-swiper-item {
    //   height: 20px;
    // }
    p {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      height: 20px;
      line-height: 20px;
      color: #666666;
    }
  }
  .goods {
    margin-top: 15px;
    .goodsType {
      margin-bottom: 25px;
      .goodsTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        h1 {
          font-size: 16px;
          color: #333333;
          margin-left: 5px;
        }
        .ckgd {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #999999;
          img {
            width: 12px;
            margin-left: 5px;
          }
        }
      }
      figure {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        margin-bottom: 15px;
        .goodsImg {
          width: 100%;
          position: relative;
          .goodsLogo {
            width: 100%;
            height: 345px;
          }
          .goodsSq {
            position: absolute;
            bottom: 17px;
            right: 10px;
            width: 50px;
          }
          .goodsTs {
            position: absolute;
            bottom: 15px;
            right: 10px;
            height: 32px;
            padding: 0 10px;
            background: #ffffff;
            border-radius: 5px;
            img {
              width: 14px;
              margin-right: 3px;
            }
            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              color: #333333;
            }
          }
          .goodsDjs {
            height: 32px;
            background: #ffffff;
            border-radius: 5px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;
            .left {
              display: flex;
              justify-content: center;
              background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
              width: 60px;
              height: 100%;
              font-size: 12px;
              color: #333333;
              align-items: center;
              img {
                width: 10px;
                flex-shrink: 0;
                margin-right: 2px;
              }
            }
            .right {
              font-size: 12px;
              padding-left: 7px;
              padding-right: 10px;
              color: #333333;
            }
          }
          .goodsQgz {
            width: 70px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
        figcaption {
          height: 85px;
          width: 100%;
          background-color: #fff;
          .goodsInfo {
            padding: 0 15px;
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
              font-size: 17px;
              color: #333333;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p {
              flex-shrink: 0;
              font-size: 22px;
              color: #333333;
              span {
                font-size: 14px;
              }
            }
          }
          .goodsAuthor {
            margin-top: 10px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
              display: flex;
              align-items: center;
              img {
                width: 18px;
                margin-right: 5px;
              }
              span {
                display: block;
                font-size: 12px;
                color: #999999;
              }
            }
            p {
              font-size: 12px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .scoreShop {
    .scoreTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      h1 {
        font-size: 16px;
        color: #333333;
        margin-left: 5px;
      }
      .ckgd {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999999;
        img {
          width: 12px;
          margin-left: 5px;
        }
      }
    }
    .scoreList {
      .scoreItem {
        width: 100%;
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0px 0px 7px 0px #eef1fd;
        border-radius: 10px;
        margin-bottom: 15px;
        .scoreItem-top {
          width: 100%;
          .scoreItem-topImg {
            width: 100%;
          }
        }
        .scoreItem-bot {
          padding: 10px 15px 22px;
          .scoreItem-title {
            div {
              font-size: 17px;
              color: #333333;
            }
          }
          .scoreItem-author {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 9px;
            .left {
              display: flex;
              align-items: center;
              img {
                width: 18px;
              }
              div {
                font-size: 12px;
                color: #999999;
                margin-left: 5px;
              }
            }
            .right {
              font-size: 16px;
              color: #333;
            }
          }
        }
      }
    }
  }
}
</style>