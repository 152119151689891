import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function getSwiper(obj) {
  return request({
    url: '/index/bannerList',
    method: 'get',
    params: obj,
  })
}
export function getNotice(obj) {
  return request({
    url: '/index/noticeList',
    method: 'get',
    params: obj,
  })
}
export function getGoods(obj) {
  return request({
    url: '/goods/goodsCategory',
    method: 'get',
    params: obj,
  })
}
export function getSearch(obj) {
  return request({
    url: '/goods/search',
    method: 'get',
    params: obj,
  })
}
export function getScore(obj) {
  return request({
    url: '/goods/integralGoodsList',
    method: 'get',
    params: obj,
  })
}
export function getShare(obj) {
  return request({
    url: '/login/sample',
    method: 'get',
    params: obj,
  })
}