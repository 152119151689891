<template>
  <div class="collection">
    <div class="collectInput">
      <img src="../assets/search.png" alt="" class="collectImg" />
      <nut-searchbar
        @click="goSearch()"
        :disabled="true"
        v-model="searchValue"
        placeholder="请输入搜索内容"
      ></nut-searchbar>
    </div>
    <div class="collectScreen">
      <div class="left">
        <div @click="changeTimeIndex()">
          <span>时间</span>
          <img v-if="timeIndex == 0" src="../assets/screenArr.png" alt="" />
          <img v-if="timeIndex == 1" src="../assets/topArr.png" alt="" />
          <img v-if="timeIndex == 2" src="../assets/botArr.png" alt="" />
        </div>
        <div @click="changeValueIndex()">
          <span>价格</span>
          <img v-if="valueIndex == 0" src="../assets/screenArr.png" alt="" />
          <img v-if="valueIndex == 1" src="../assets/topArr.png" alt="" />
          <img v-if="valueIndex == 2" src="../assets/botArr.png" alt="" />
        </div>
      </div>
      <div class="right" v-if="!searchSxTF" @click="changSx()">
        <span>筛选</span>
        <img src="../assets/screen.png" alt="" />
      </div>
    </div>
    <div v-if="searchSxTF" class="collectTag">
      {{ searchName }}
      <i
        @click="delectSearch()"
        class="nutui-iconfont nut-icon nut-icon-failure close"
      ></i>
    </div>
    <div class="collectNew">
      <div class="collectTitle">{{ typeTitle }}</div>
      <div class="collectList">
        <nut-infiniteloading
          containerId="scroll"
          :use-window="true"
          :has-more="hasMore"
          @load-more="loadMore"
          :load-icon="false"
          :load-more-txt="finishedText"
        >
          <figure
            @click="goDetail(item.id)"
            v-for="item in collectData"
            :key="item.id"
          >
            <div class="collectTop">
              <img :src="item.image" alt="" class="collect-img" />
              <img
                src="../assets/sq.png"
                alt=""
                class="goodsSq"
                v-if="item.status == 2"
              />
              <div class="goodsTs" v-if="item.status == 3">
                <div class="left">
                  <img src="../assets/rili.png" alt="" />{{ item.status_text }}
                </div>
              </div>
              <div class="goodsDjs" v-if="item.status == 4">
                <div class="left">
                  <img src="../assets/djs.png" alt="" />
                  倒计时
                </div>
                <div class="right">{{ item.djsTime }}</div>
              </div>
              <div class="goodsQgz" v-if="item.status == 1">
                <img src="../assets/qgz.png" alt="" />
              </div>
            </div>
            <figcaption>
              <div class="collect-name">
                <div class="left">{{ item.name }}</div>
                <div class="right">
                  ￥<span>{{ item.price }}</span>
                </div>
              </div>
              <div class="collect-author">
                <div class="left">
                  <img :src="item.author_image" alt="" />
                  <span>{{ item.author_name }}</span>
                </div>
                <div class="right">限量{{ item.stock }}份</div>
              </div>
            </figcaption>
          </figure>
        </nut-infiniteloading>
      </div>
    </div>
    <div class="collectType" v-if="sxTF">
      <div class="collect-zzc" @click="changSx()"></div>
      <div class="collectSide">
        <h1>主题分类</h1>
        <div class="collectSide-list">
          <div
            :class="currentIndex == index ? 'active' : ''"
            @click="changeCurrentIndex(index, item)"
            v-for="(item, index) in collectObj"
            :key="item.id"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <navBar></navBar>
  </div>
</template>

<script>
import {
  onMounted,
  onActivated,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import navBar from "../components/nav.vue";

import { Toast } from "@nutui/nutui";
import { getCollect, getCollectType } from "../api/collection.js";

export default {
  components: { navBar },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/detail") {
      to.meta.fromDetail = true;
    } else {
      to.meta.fromDetail = false;
    }
    if (from.path == "/search") {
      to.meta.fromSearch = true;
    } else {
      to.meta.fromSearch = false;
    }
    next((e) => {});
  },
  setup() {
    const hasMore = ref(true);
    const loadMore = (done) => {
      setTimeout(() => {
        if (state.collectCount <= state.collectData.length) {
          hasMore.value = false;
        } else {
          state.params.page += 1;
          getCollectData();
        }
        done();
      }, 500);
    };
    const $router = useRouter();
    const $route = useRoute();
    const resetAll = () => {
      hasMore.value = true;
      state.collectData = [];
      state.timeIndex = 0;
      state.searchValue = "";
      state.valueIndex = 0;
      state.collectData = [];
      state.collectObj = [];
      state.searchName = "";
      state.searchSxTF = false;
      state.timeOut = "";
      state.typeTitle = "";
      state.sxTF = false;
      state.currentIndex = 0;
      state.params = {
        search: "",
        page: 1,
        pagesize: 10,
        goods_category_id: "",
        is_home: "",
        price_order: "",
        time_order: "",
        author_id: "",
        goods_id: "",
      };
    };
    onActivated(() => {
      if (!$route.meta.fromDetail) {
        resetAll();
        if ($route.query.id != undefined) {
          state.params.goods_id = $route.query.id;
          state.searchName = $route.query.name;
          state.searchSxTF = true;
        } else {
          getCollectTypeData();
        }
        if ($route.query.goods_category_id != undefined) {
          state.params.goods_category_id = $route.query.goods_category_id;
          state.currentIndex = $route.query.index;
          state.typeTitle = $route.query.name;
        }
        getCollectData();
      }
      if ($route.meta.fromSearch) {
        if ($route.query.id != undefined) {
          state.searchName = $route.query.name;
          state.searchSxTF = true;
          state.params.goods_id = $route.query.id;
          getCollectData();
        }
      }
    });
    const state = reactive({
      searchValue: "",
      timeIndex: 0,
      pageIndex: 2,
      finishedText: "暂无更多数据",
      searchName: "",
      collectCount: "",
      valueIndex: 0,
      collectData: [],
      collectObj: [],
      timeOut: "",
      currentIndex: 0,
      searchSxTF: false,
      typeTitle: "",
      sxTF: false,
      params: {
        search: "",
        page: 1,
        pagesize: 10,
        goods_category_id: "",
        is_home: "",
        price_order: "",
        time_order: "",
        author_id: "",
        goods_id: "",
      },
    });

    onMounted(() => {});
    const delectSearch = () => {
      resetAll();
      resetData();
    };
    const changeCurrentIndex = (index, item) => {
      state.currentIndex = index;
      state.typeTitle = item.name;
      state.params.goods_category_id = item.id;
      resetData();
      changSx();
    };
    const changSx = () => {
      state.sxTF = !state.sxTF;
    };
    const getCollectTypeData = () => {
      let params = {};
      getCollectType(params).then((res) => {
        state.collectObj = res.data;
        if ($route.query.id != undefined) {
          state.typeTitle = "藏品";
        } else {
          if ($route.query.goods_category_id != undefined) {
          } else {
            state.typeTitle = res.data[0].name;
          }
        }
      });
    };
    const goSearch = () => {
      $router.push("/search");
    };
    const changeValueIndex = () => {
      if (state.valueIndex >= 2) {
        state.valueIndex = 0;
      } else {
        state.valueIndex += 1;
      }
      state.params.price_order = state.valueIndex == 0 ? "" : state.valueIndex;
      resetData();
    };
    const changeTimeIndex = () => {
      if (state.timeIndex >= 2) {
        state.timeIndex = 0;
      } else {
        state.timeIndex += 1;
      }
      state.params.time_order = state.timeIndex == 0 ? "" : state.timeIndex;
      resetData();
    };
    const resetData = () => {
      state.collectData = [];
      state.params.page = 1;
      getCollectData();
    };
    const goDetail = (id) => {
      $router.push({
        path: "/detail",
        query: {
          id: id,
        },
      });
    };
    const getCollectData = () => {
      getCollect(state.params).then((res) => {
        state.collectCount = res.data.count;
        state.collectData = state.collectData.concat(res.data.data);
        state.timeOut = setInterval(() => {
          for (let i in state.collectData) {
            if (state.collectData[i].status == 4) {
              if (state.collectData[i].diff_time <= 0) {
                state.collectData[i].status = 1;
              } else {
                let second_time = parseInt(state.collectData[i].diff_time);
                let time = second_time + "秒";
                if (parseInt(second_time) > 60) {
                  let second = parseInt(second_time) % 60;
                  let min = parseInt(second_time / 60);
                  time = min + "分" + second + "秒";

                  if (min > 60) {
                    min = parseInt(second_time / 60) % 60;
                    let hour = parseInt(parseInt(second_time / 60) / 60);
                    time = hour + "小时" + min + "分" + second + "秒";

                    if (hour > 24) {
                      hour = parseInt(parseInt(second_time / 60) / 60) % 24;
                      let day = parseInt(
                        parseInt(parseInt(second_time / 60) / 60) / 24
                      );
                      time = day + "天" + hour + "小时" + min + "分";
                    }
                  }
                }
                state.collectData[i].djsTime = time;
                state.collectData[i].diff_time -= 1;
              }
            }
          }
        }, 1000);
      });
    };

    return {
      ...toRefs(state),
      loadMore,
      hasMore,
      goSearch,
      changeTimeIndex,
      changeValueIndex,
      goDetail,
      changSx,
      changeCurrentIndex,
      delectSearch,
    };
  },
};
</script>
<style lang="scss">
.collection {
  padding: 15px;
  padding-bottom: 80px;
  .collectInput {
    position: relative;
    .collectImg {
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
    }
    .nut-searchbar {
      padding: 0;
      background-color: unset;
      .nut-searchbar__search-input {
        height: 35px;
        box-shadow: unset;
        background-color: #e9eaec;
        .nut-searchbar__input-inner {
          font-size: 12px;
          input {
            text-indent: 21px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .collectScreen {
    display: flex;
    margin-top: 13px;
    align-items: center;
    padding-left: 5px;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      div {
        display: flex;
        margin-right: 15px;
        align-items: center;
        span {
          font-size: 13px;
          color: #999999;
        }
        img {
          width: 14px;
          margin-left: 1px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      padding-right: 6px;
      span {
        font-size: 12px;
        color: #666666;
      }
      img {
        width: 14px;
      }
    }
  }
  .collectTag {
    width: 125px;
    height: 35px;
    background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
    border-radius: 5px;
    font-size: 14px;
    color: #333333;
    height: 35px;
    margin-top: 10px;
    text-align: center;
    position: relative;
    line-height: 35px;
    i {
      font-size: 12px;
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }
  .collectNew {
    margin-top: 20px;
    .collectTitle {
      font-size: 16px;
      color: #333333;
      padding-left: 5px;
      margin-bottom: 15px;
      font-weight: bold;
    }
    .collectList {
      figure {
        background-color: #fff;
        box-shadow: 0px 0px 7px 0px #eef1fd;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        padding-bottom: 20px;
        width: 100%;
        .collectTop {
          width: 100%;
          position: relative;
          .collect-img {
            width: 100%;
          }
          .goodsSq {
            position: absolute;
            bottom: 17px;
            right: 10px;
            width: 50px;
          }
          .goodsTs {
            position: absolute;
            bottom: 15px;
            right: 10px;
            height: 32px;
            padding: 0 10px;
            background: #ffffff;
            border-radius: 5px;
            img {
              width: 14px;
              margin-right: 3px;
            }
            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              color: #333333;
            }
          }
          .goodsDjs {
            height: 32px;
            background: #ffffff;
            border-radius: 5px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;
            .left {
              display: flex;
              justify-content: center;
              background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
              width: 60px;
              height: 100%;
              font-size: 12px;
              color: #333333;
              align-items: center;
              img {
                width: 10px;
                flex-shrink: 0;
                margin-right: 2px;
              }
            }
            .right {
              font-size: 12px;
              padding-left: 7px;
              padding-right: 10px;
              color: #333333;
            }
          }
          .goodsQgz {
            width: 70px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
        figcaption {
          .collect-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            margin-top: 12px;
            margin-bottom: 9px;
            .left {
              font-size: 17px;
              color: #333333;
            }
            .right {
              font-size: 14px;
              color: #333333;
              span {
                font-size: 22px;
                color: #333333;
              }
            }
          }
          .collect-author {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            .left {
              display: flex;
              align-items: center;
              img {
                width: 18px;
                margin-right: 5px;
              }
              span {
                font-size: 12px;
                color: #999999;
              }
            }
            .right {
              font-size: 12px;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .collectType {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    .collect-zzc {
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
    .collectSide {
      width: 205px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #fff;
      h1 {
        font-size: 16px;
        color: #333333;
        margin-top: 20px;
        margin-left: 15px;
      }
      .collectSide-list {
        padding: 0 15px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
          width: 80px;
          height: 30px;
          font-size: 14px;
          color: #333333;
          margin-bottom: 15px;
          background: #f3f4f9;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div.active {
          background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
        }
      }
    }
  }
}
</style>