<template>
  <div class="blindBox">
    <div class="blindBoxInput">
      <img src="../assets/search.png" alt="" class="collectImg" />
      <nut-searchbar
        v-model="searchValue"
        placeholder="请输入搜索内容"
        @change="changeValue"
      ></nut-searchbar>
      <div class="blindBox-Search" @click="goSearch()">搜索</div>
    </div>
    <div class="collectScreen">
      <div class="left">
        <div @click="changeTimeIndex()">
          <span>时间</span>
          <img v-if="timeIndex == 0" src="../assets/screenArr.png" alt="" />
          <img v-if="timeIndex == 1" src="../assets/topArr.png" alt="" />
          <img v-if="timeIndex == 2" src="../assets/botArr.png" alt="" />
        </div>
        <div @click="changeValueIndex()">
          <span>价格</span>
          <img v-if="valueIndex == 0" src="../assets/screenArr.png" alt="" />
          <img v-if="valueIndex == 1" src="../assets/topArr.png" alt="" />
          <img v-if="valueIndex == 2" src="../assets/botArr.png" alt="" />
        </div>
      </div>
    </div>
    <div class="collectNew">
      <div class="collectTitle">{{ typeTitle }}</div>
      <div class="collectList">
        <figure
          @click="goDetail(item.id)"
          v-for="item in bilndBoxData"
          :key="item.id"
        >
          <div class="collectTop">
            <img :src="item.image" alt="" class="collect-img" />
            <img
              src="../assets/sq.png"
              alt=""
              class="goodsSq"
              v-if="item.status == 2"
            />
            <div class="goodsTs" v-if="item.status == 3">
              <div class="left">
                <img src="../assets/rili.png" alt="" />{{ item.status_text }}
              </div>
            </div>
            <div class="goodsDjs" v-if="item.status == 4">
              <div class="left">
                <img src="../assets/djs.png" alt="" />
                倒计时
              </div>
              <div class="right">{{ item.djsTime }}</div>
            </div>
            <div class="goodsQgz" v-if="item.status == 1">
              <img src="../assets/qgz.png" alt="" />
            </div>
          </div>
          <figcaption>
            <div class="collect-name">
              <div class="left">{{ item.name }}</div>
              <div class="right">
                ￥<span>{{ item.price }}</span>
              </div>
            </div>
            <!--<div class="collect-author">
              <div class="left">
                <img :src="item.author_image" alt="" />
                <span>{{ item.author_name }}</span>
              </div>
              <div class="right">限量{{ item.order }}份</div>
            </div> -->
          </figcaption>
        </figure>
      </div>
    </div>
    <navBar></navBar>
  </div>
</template>

<script>
import { onMounted, onActivated, reactive, toRefs, defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import navBar from "../components/nav.vue";
import wx from "weixin-js-sdk";
import { Toast } from "@nutui/nutui";
import { getBlindBox } from "../api/blindBox.js";

export default {
  components: { navBar },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/blindDetail") {
      to.meta.fromDetail = true;
    } else {
      to.meta.fromDetail = false;
    }
    next((e) => {});
  },
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    onActivated(() => {
      if (!$route.meta.fromDetail) {
        state.timeIndex = 0;
        state.searchValue = "";
        state.valueIndex = 0;
        state.bilndBoxData = [];
        state.collectObj = [];
        state.timeOut = "";
        state.currentIndex = 0;
        state.params = {
          search: "",
          page: 1,
          pagesize: 10,
          price_order: "",
          time_order: "",
        };
        getBlindBoxData();
      }
    });
    const state = reactive({
      searchValue: "",
      timeIndex: 0,
      pageIndex: 2,
      valueIndex: 0,
      bilndBoxData: [],
      collectObj: [],
      timeOut: "",
      currentIndex: 0,
      typeTitle: "盲盒",
      params: {
        search: "",
        page: 1,
        pagesize: 10,
        price_order: "",
        time_order: "",
      },
    });

    onMounted(() => {});
    const goSearch = () => {
      state.params.search = state.searchValue;
      resetData();
    };
    const changeCurrentIndex = (index, item) => {
      state.currentIndex = index;
      state.params.goods_category_id = item.id;
      resetData();
    };
    const changeValueIndex = () => {
      if (state.valueIndex >= 2) {
        state.valueIndex = 0;
      } else {
        state.valueIndex += 1;
      }
      state.params.price_order = state.valueIndex == 0 ? "" : state.valueIndex;
      resetData();
    };
    const changeTimeIndex = () => {
      if (state.timeIndex >= 2) {
        state.timeIndex = 0;
      } else {
        state.timeIndex += 1;
      }
      state.params.time_order = state.timeIndex == 0 ? "" : state.timeIndex;
      resetData();
    };
    const resetData = () => {
      state.bilndBoxData = [];
      state.params.page = 1;
      getBlindBoxData();
    };
    const goDetail = (id) => {
      $router.push({
        path: "/blindDetail",
        query: {
          id: id,
        },
      });
    };
    const getBlindBoxData = () => {
      getBlindBox(state.params).then((res) => {
        console.log(res.data);
        state.bilndBoxData = state.bilndBoxData.concat(res.data.data);
        state.timeOut = setInterval(() => {
          for (let i in state.bilndBoxData) {
            if (state.bilndBoxData[i].status == 4) {
              if (state.bilndBoxData[i].diff_time <= 0) {
                state.bilndBoxData[i].status = 1;
              } else {
                let second_time = parseInt(state.bilndBoxData[i].diff_time);
                let time = second_time + "秒";
                if (parseInt(second_time) > 60) {
                  let second = parseInt(second_time) % 60;
                  let min = parseInt(second_time / 60);
                  time = min + "分" + second + "秒";

                  if (min > 60) {
                    min = parseInt(second_time / 60) % 60;
                    let hour = parseInt(parseInt(second_time / 60) / 60);
                    time = hour + "小时" + min + "分" + second + "秒";

                    if (hour > 24) {
                      hour = parseInt(parseInt(second_time / 60) / 60) % 24;
                      let day = parseInt(
                        parseInt(parseInt(second_time / 60) / 60) / 24
                      );
                      time = day + "天" + hour + "小时" + min + "分";
                    }
                  }
                }
                state.bilndBoxData[i].djsTime = time;
                state.bilndBoxData[i].diff_time -= 1;
              }
            }
          }
        }, 1000);
      });
    };

    return {
      ...toRefs(state),
      changeTimeIndex,
      changeValueIndex,
      goDetail,
      goSearch,
      changeCurrentIndex,
    };
  },
};
</script>
<style lang="scss">
.blindBox {
  padding: 15px;
  padding-bottom: 65px;
  .blindBoxInput {
    position: relative;
    display: flex;
    align-items: center;
    .collectImg {
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
    }
    .blindBox-Search {
      font-size: 14px;
      color: #303133;
      flex-shrink: 0;
      margin-left: 5px;
    }
    .nut-searchbar {
      padding: 0;
      background-color: unset;
      .nut-searchbar__search-input {
        height: 35px;
        box-shadow: unset;
        background-color: #e9eaec;
        .nut-searchbar__input-inner {
          font-size: 12px;
          input {
            text-indent: 21px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .collectScreen {
    display: flex;
    margin-top: 13px;
    align-items: center;
    padding-left: 5px;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      div {
        display: flex;
        margin-right: 15px;
        align-items: center;
        span {
          font-size: 13px;
          color: #999999;
        }
        img {
          width: 14px;
          margin-left: 1px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      padding-right: 6px;
      span {
        font-size: 12px;
        color: #666666;
      }
      img {
        width: 14px;
      }
    }
  }
  .collectNew {
    margin-top: 20px;
    .collectTitle {
      font-size: 16px;
      color: #333333;
      padding-left: 5px;
      margin-bottom: 15px;
      font-weight: bold;
    }
    .collectList {
      figure {
        background-color: #fff;
        box-shadow: 0px 0px 7px 0px #eef1fd;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        width: 100%;
        .collectTop {
          width: 100%;
          position: relative;
          .collect-img {
            width: 100%;
          }
          .goodsSq {
            position: absolute;
            bottom: 17px;
            right: 10px;
            width: 50px;
          }
          .goodsTs {
            position: absolute;
            bottom: 15px;
            right: 10px;
            height: 32px;
            padding: 0 10px;
            background: #ffffff;
            border-radius: 5px;
            img {
              width: 14px;
              margin-right: 3px;
            }
            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              color: #333333;
            }
          }
          .goodsDjs {
            height: 32px;
            background: #ffffff;
            border-radius: 5px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;
            .left {
              display: flex;
              justify-content: center;
              background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
              width: 60px;
              height: 100%;
              font-size: 12px;
              color: #333333;
              align-items: center;
              img {
                width: 10px;
                flex-shrink: 0;
                margin-right: 2px;
              }
            }
            .right {
              font-size: 12px;
              padding-left: 7px;
              padding-right: 10px;
              color: #333333;
            }
          }
          .goodsQgz {
            width: 70px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
        figcaption {
          padding: 20px 0;
          .collect-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            .left {
              font-size: 17px;
              color: #333333;
            }
            .right {
              font-size: 14px;
              color: #333333;
              span {
                font-size: 22px;
                color: #333333;
              }
            }
          }
          .collect-author {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            .left {
              display: flex;
              align-items: center;
              img {
                width: 18px;
                margin-right: 5px;
              }
              span {
                font-size: 12px;
                color: #999999;
              }
            }
            .right {
              font-size: 12px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
</style>