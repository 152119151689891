import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../views/index.vue'
import detail from '../views/detail.vue'
import blindDetail from '../views/blindDetail.vue'
import collection from '../views/collection.vue'
import blindBox from '../views/blindBox.vue'
const routes = [
  {
    path: '/index',
    name: 'index',
    component: index,
    meta: {
      keepAlive: true,
      fromDetail: false,
    }
  },
  {
    path: '/collection',
    name: 'collection',
    component: collection,
    meta: {
      keepAlive: true,
      fromDetail: false,
    }
  },
  {
    path: '/blindBox',
    name: 'blindBox',
    component: blindBox,
    meta: {
      keepAlive: true,
      fromDetail: false,
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/blindDetail',
    name: 'blindDetail',
    component: blindDetail,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/notice.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: () => import('../views/noticeDetail.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/collectDetail',
    name: 'collectDetail',
    component: () => import('../views/collectDetail.vue'),
    meta: {
      keepAlive: false
    }
  },

  {
    path: '/person',
    name: 'person',
    component: () => import('../views/person.vue'),
    meta: {
      keepAlive: true,
      fromDetail: false,
    }
  },
  {
    path: '/modifyInfo',
    name: 'modifyInfo',
    component: () => import('../views/modifyInfo.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/realName',
    name: 'realName',
    component: () => import('../views/realName.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/install',
    name: 'install',
    component: () => import('../views/install.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/aboutNFT',
    name: 'aboutNFT',
    component: () => import('../views/aboutNFT.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/aboutWm',
    name: 'aboutWm',
    component: () => import('../views/aboutWm.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/scoreShop',
    name: 'scoreShop',
    component: () => import('../views/scoreShop.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/balance',
    name: 'balance',
    component: () => import('../views/balance.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '',
    redirect: '/index'
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

export default router
