<template>
  <div class="detail">
    <div class="detailCon">
      <div :class="loadTF ? 'top' : 'top displayNone'">
        <img src="../assets/back.png" alt="" @click="goBack()" class="back" />
        <img class="goodsImage" v-if="goodsDetailData.is_type != 2" :src="goodsDetailData.image" alt="" />
        <div class="goodsVideo" v-if="goodsDetailData.is_type == 2">
          <video id="videoPlayer" x5-video-player-type="h5" x5-video-player-fullscreen="true" playsinline="true"
            webkit-playsinline="true" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9">
            <source :src="goodsDetailData.tv" />
          </video>
        </div>
        <img src="../assets/zuo.png" alt="" class="goodsZuo" />
        <div class="goodsQgz" v-if="goodsDetailData.status == 1">
          <img src="../assets/qgz.png" alt="" />
        </div>
        <img src="../assets/sq.png" alt="" class="goodsSq" v-if="goodsDetailData.status == 2" />
        <div class="goodsTs" v-if="goodsDetailData.status == 3">
          <div class="left">
            <img src="../assets/rili.png" alt="" />{{
              goodsDetailData.status_text
            }}
          </div>
        </div>
        <div class="goodsDjs" v-if="goodsDetailData.status == 4 && timeStart">
          <div class="left">
            <img src="../assets/djs.png" alt="" />
            倒计时
          </div>
          <div class="right">{{ goodsDetailData.djsTime }}</div>
        </div>
      </div>
      <div class="bot">
        <div class="botLeft">{{ goodsDetailData.name }}</div>
        <div class="botRight">
          <p><span>￥</span>{{ goodsDetailData.price }}</p>
          <div>限量{{ goodsDetailData.stock }}份</div>
        </div>
      </div>
    </div>
    <div class="detailAuthor">
      <div class="autLeft">
        <img :src="goodsDetailData.author_image" alt="" class="autImg" />
        <div class="autName">
          <p>{{ goodsDetailData.author_name }}</p>
          <img src="../assets/rz.png" alt="" />
        </div>
      </div>
      <!-- <div class="autRight">查看主页 ></div> -->
    </div>
    <div class="detailPart">
      <img src="../assets/titleLeft.png" alt="" />
      <h1>认证信息</h1>
      <img src="../assets/titleRight.png" alt="" />
    </div>
    <div class="detailRzxx">
      <div class="rzxxItem">
        <div class="rzxx-left">标识</div>
        <div class="rzxx-center">{{ goodsDetailData.asset_id }}</div>
        <div class="rzxx-right" v-if="goodsDetailData.asset_id != null">
          <button class="tag-read" :data-clipboard-text="copyText" @click="copy(goodsDetailData.asset_id)">
            <img src="../assets/copy.png" alt="" />
          </button>
        </div>
      </div>
      <div class="rzxxItem">
        <div class="rzxx-left">交易凭证</div>
        <div class="rzxx-center">{{ goodsDetailData.tx_id }}</div>
        <div class="rzxx-right" v-if="goodsDetailData.tx_id != null">
          <button class="tag-read" :data-clipboard-text="copyText" @click="copy(goodsDetailData.tx_id)">
            <img src="../assets/copy.png" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div class="detailPart detailPart1" v-if="goodsDetailData.content != ''">
      <img src="../assets/titleLeft.png" alt="" />
      <h1>藏品介绍</h1>
      <img src="../assets/titleRight.png" alt="" />
    </div>
    <div class="detailCpjs" v-if="goodsDetailData.content != ''" v-html="goodsDetailData.content"></div>
    <div class="detailGmxz">
      <div class="detailPart detailPart2">
        <img src="../assets/titleLeft.png" alt="" />
        <h1>购买须知</h1>
        <img src="../assets/titleRight.png" alt="" />
      </div>
      <p>
        获得此数藏空投权限的准车主，可以购买，每个账号只能购买一份，购买后可在【车讯数藏—我的】中查看。

        本期数字藏品都有唯一编号，且不可篡改。数字藏品的版权归属不随交易而变更，除用户获得版权方的书面同意外，用户不得将数手藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。该数字藏品一经售出，将不支持退换。

        数字藏品仅支持14周岁及以上用户购买，请您购买前完成实名认证。为了保证数字藏品能顺利发放到您的账号，请您通过【车讯数藏】平台购买数字藏品。

        请注意:请您空投到数字藏品后，尽快完成支付。未在2分钟内完成支付的，会被视为无效订单或自行放弃购买资格，此时库存会被释放。若成功完成支付，该数字藏品将会下发到您的账号。受网影响，部分藏品上链速度较慢，请耐心等待。
      </p>
      <div class="gmxzLjgm" @click="pay()">立即购买</div>
    </div>

    <payTag class="payTag" :goodsId="goodsId" :tagType="tagType" @getPayTag="getPayTag" v-if="payTagTF">
    </payTag>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { reactive, toRefs, onUnmounted, getCurrentInstance } from "vue";
import { getGoodsDetail, getUserCode, getOrder } from "../api/detail.js";
import { getUserInfo, getUserAcc } from "../api/person.js";
import { getShare } from "../api/index.js";
import { Toast } from "@nutui/nutui";
import payTag from "../components/payTag.vue";
import Video from "video.js";
import "video.js/dist/video-js.min.css";
import wx from "weixin-js-sdk";

export default {
  components: {
    payTag,
  },
  data() {
    return {
      userAgent: "",
      tagType: 0,
      goodsId: "",
      goodsDetailData: {},
      timeStart: false,
      copyText: "",
      loadTF: false,
      payIndex: 0,
      payTagTF: false,
      wxPayTF: false,
      userAccData: {},
      player: "",
      playOn: "",
    };
  },
  setup() {
    const dataObj = getCurrentInstance();
    const state = reactive({
      page: 0,
      timeOut: "",
    });
    onUnmounted(() => {
      if (dataObj.data.goodsDetailData.state == 4) {
        clearInterval(state.timeOut);
        state.timeOut = "";
      }
    });
  },
  mounted() {
    this.userAgent = navigator.userAgent.toLowerCase();
    this.goodsId = this.$route.query.id;
    this.getGoodsDetailData();
    if (this.userAgent.match(/MicroMessenger/i) == "micromessenger") {
      this.shareMsg();
    }

  },
  unmounted() {
    if (this.goodsDetailData.is_type == 2) {
      this.player.dispose(); //销毁video
      console.log("已销毁");
      clearInterval(this.playOn);
    }
  },

  methods: {
    shareMsg() {
      getShare().then((res) => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的 JS 接口列表
        });
        var shareData = {
          title: this.goodsDetailData.name,
          desc: this.goodsDetailData.desc,
          link: window.location.href.replace(window.location.search, ""),
          imgUrl: this.goodsDetailData.image,
        };
        //点击要去分享
        wx.ready(function () {
          //分享给好友
          wx.updateAppMessageShareData({
            title: shareData.title, // 分享标题
            desc: shareData.desc, //分享的描述
            link: shareData.link, // 分享链接，该链接域名必须与当前企业的可信域名一致
            imgUrl: shareData.imgUrl, // 分享图标
            success: function () { },
          });
          //分享到朋友圈
          wx.updateTimelineShareData({
            title: shareData.title, // 分享标题
            link: shareData.link, // 分享链接，该链接域名必须与当前企业的可信域名一致
            imgUrl: shareData.imgUrl, // 分享图标
            success: function () { },
          });
        });
      });
    },
    getPayTag(e) {
      this.payTagTF = e;
    },

    changePayIndex(index) {
      this.payIndex = index;
    },
    pay() {
      if (!localStorage.getItem("app_token")) {
        this.goLogin();
      } else {
        this.payTagTF = true;
      }
    },
    goLogin() {
      this.$router.push("/login");
    },
    goBack() {
      this.$router.go(-1);
    },
    copy(item) {
      this.copyText = item;
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        Toast.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        Toast.fail("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    getGoodsDetailData() {
      let params = {
        id: this.goodsId,
      };
      getGoodsDetail(params).then((res) => {
        console.log(res.data);
        this.goodsDetailData = res.data;
        this.loadTF = true;
        if (this.goodsDetailData.is_type == 2) {
          this.$nextTick(() => {
            let that = this;
            this.player = Video(
              "videoPlayer",
              {
                loop: true, //循环播放
                controls: false, // 控制条是否展示
                autoplay: true, // 自动播放
                muted: true, // 静音
                preload: "auto", // 预加载
              },
              function onPlayerReady() {
                console.log("onPlayerReady");
              }
            );
            document.addEventListener("touchstart", () => {
              this.player.play();
            });
          });
        }

        if (this.goodsDetailData.status == 4) {
          this.timeOut = setInterval(() => {
            if (this.goodsDetailData.diff_time <= 0) {
              this.goodsDetailData.status = 1;
              this.timeStart = false;
            } else {
              this.timeStart = true;
              let second_time = parseInt(this.goodsDetailData.diff_time);
              let time = second_time + "秒";
              if (parseInt(second_time) > 60) {
                var second = parseInt(second_time) % 60;
                var min = parseInt(second_time / 60);
                time = min + "分" + second + "秒";

                if (min > 60) {
                  min = parseInt(second_time / 60) % 60;
                  var hour = parseInt(parseInt(second_time / 60) / 60);
                  time = hour + "小时" + min + "分" + second + "秒";

                  if (hour > 24) {
                    hour = parseInt(parseInt(second_time / 60) / 60) % 24;
                    var day = parseInt(
                      parseInt(parseInt(second_time / 60) / 60) / 24
                    );
                    time = day + "天" + hour + "小时" + min + "分";
                  }
                }
              }
              this.goodsDetailData.djsTime = time;
              this.goodsDetailData.diff_time -= 1;
            }
          }, 1000);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.detail {
  video {
    object-fit: cover;
  }

  .detailCpjs {
    background-color: #fff;
    padding: 15px 0;
    font-size: 14px;

    p {
      padding: 0 15px;
    }

    img {
      width: 100% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.detail {
  .displayNone {
    opacity: 0;
  }

  .detailCon {
    background-color: #ffffff;
    box-shadow: 0px 0px 7px 0px #eef1fd;
    padding-bottom: 14px;

    .top {
      width: 100%;
      position: relative;
      height: 375px;
      background-image: url(../assets/detailBg.png);
      background-size: 100% auto;
      background-repeat: no-repeat;

      // background-color: #fff;
      .back {
        width: 16px;
        position: absolute;
        top: 17px;
        left: 15px;
        z-index: 1;
      }

      .goodsZuo {
        width: 356px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .goodsImage {
        width: 230px;
        height: 230px;
        box-shadow: 0px 2px 7px 0px rgba(161, 161, 161, 0.5);
        border-radius: 10px;
        border: 2px solid #ffffff;
        position: absolute;
        top: 33px;
        left: 0;
        right: 0;
        margin: auto;
        animation: leftToRight linear 5.5s infinite;
        // transition: all linear 3s;
        // transform: rotate3d(0, 1, 0, 30deg);
      }

      .goodsVideo {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        margin: auto;
        width: 316px;
        box-shadow: 0px 2px 7px 0px rgba(161, 161, 161, 0.5);
        border-radius: 10px;
        border: 2px solid #ffffff;
        overflow: hidden;

        video {
          display: block;
          width: 100%;
          margin: 0 auto;
        }
      }

      // @keyframes leftToRight {
      //   0% {
      //     transform: rotate3d(0, 1, 0, 0deg);
      //   }
      //   25% {
      //     transform: rotate3d(0, 1, 0, -30deg);
      //   }
      //   50% {
      //     transform: rotate3d(0, 1, 0, 0deg);
      //   }
      //   75% {
      //     transform: rotate3d(0, 1, 0, 30deg);
      //   }
      //   100% {
      //     transform: rotate3d(0, 1, 0, 0deg);
      //   }
      //   // 90% {
      //   //   transform: rotateY(30deg);
      //   // }
      //   // 100% {
      //   //   transform: rotateY(15deg);
      //   // }
      // }
      @-webkit-keyframes leftToRight {
        0% {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }

        50% {
          transform: perspective(500px) rotateX(0deg) rotateY(-15deg);
        }

        to {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }
      }

      @keyframes leftToRight {
        0% {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }

        50% {
          transform: perspective(500px) rotateX(0deg) rotateY(-15deg);
        }

        to {
          transform: perspective(500px) rotateX(0deg) rotateY(15deg);
        }
      }

      .goodsSq {
        position: absolute;
        bottom: 0;
        right: 13px;
        width: 50px;
      }

      .goodsQgz {
        width: 70px;
        position: absolute;
        right: 10px;
        bottom: 0;

        img {
          width: 100%;
        }
      }

      .goodsTs {
        position: absolute;
        bottom: 0;
        right: 10px;
        height: 32px;
        padding: 0 10px;
        background: #ffffff;
        border-radius: 5px;

        img {
          width: 14px;
          margin-right: 3px;
        }

        div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #333333;
        }
      }

      .goodsDjs {
        height: 32px;
        background: #ffffff;
        border-radius: 5px;
        position: absolute;
        right: 10px;
        bottom: 0;
        display: flex;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;

        .left {
          display: flex;
          justify-content: center;
          background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
          width: 60px;
          height: 100%;
          font-size: 12px;
          color: #333333;
          align-items: center;

          img {
            width: 10px;
            flex-shrink: 0;
            margin-right: 2px;
          }
        }

        .right {
          font-size: 12px;
          padding-left: 7px;
          padding-right: 10px;
          color: #333333;
        }
      }
    }

    .bot {
      padding-top: 18px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .botLeft {
        margin-left: 15px;
        font-size: 18px;
        color: #333333;
      }

      .botRight {
        margin-right: 15px;
        text-align: right;

        p {
          font-size: 22px;
          color: #333333;

          span {
            font-size: 14px;
          }
        }

        div {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .detailAuthor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #ffffff;
    margin-top: 10px;

    .autLeft {
      display: flex;
      align-items: center;

      .autImg {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .autName {
        p {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }

        img {
          margin-top: 4px;
          width: 26px;
        }
      }
    }

    .autRight {
      font-size: 12px;
      color: #666666;
    }
  }

  .detailPart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;

    img {
      width: 72px;
    }

    h1 {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
      margin: 0 5px;
    }
  }

  .detailPart1 {
    margin-top: 30px;
  }

  .detailRzxx {
    background: #ffffff;
    padding: 15px;

    .rzxxItem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .rzxx-left {
        font-size: 14px;
        color: #999999;
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
      }

      .rzxx-center {
        flex: 1;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #999999;
      }

      .rzxx-right {
        width: 16px;

        button {
          display: block;
          border: 0;
          outline: none;
          width: 100%;
          background-color: unset;

          img {
            width: 100%;
          }
        }
      }
    }

    .rzxxItem:last-child {
      margin-bottom: 0;
    }
  }

  .detailGmxz {
    padding: 30px;

    margin-top: 18px;
    background-color: #fff;

    .detailPart2 {
      margin-top: 0;
    }

    p {
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      margin-bottom: 26px;
    }

    .gmxzLjgm {
      width: 100%;
      height: 50px;
      background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
      border-radius: 5px;
      font-size: 16px;
      color: #333333;
      text-align: center;
      line-height: 50px;
    }
  }

  .payTag {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;

    .payTag-zzc {
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.7);
    }

    .payTag-div {
      padding-bottom: 30px;
      background: #ffffff;
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      h1 {
        font-size: 15px;
        color: #333333;
        padding-top: 11px;
        text-align: center;
      }

      .payType {
        margin-top: 14px;
        padding: 0 22px;

        .payType-item {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .left {
            width: 20px;
            margin-right: 7px;
          }

          .left1 {
            width: 22px;
            margin-right: 5px;
          }

          p {
            flex: 1;
            font-size: 14px;
            color: #666666;

            span {
              font-size: 13px;
              color: #0077d4;
            }
          }

          .right {
            width: 18px;
          }
        }
      }

      .payBut {
        width: 305px;
        height: 50px;
        background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
        border-radius: 5px;
        margin: 0 auto;
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 50px;
      }
    }
  }
}
</style>