<template>
  <div class="home">
    <div class="tab">
      <div class="tabItem" @click="changeIndex(0)">
        <img v-if="currentIndex != 0" src="../assets/find.png" alt="" />
        <img v-if="currentIndex == 0" src="../assets/find1.png" alt="" />
        <p :class="currentIndex == 0 ? 'active' : ''">发现</p>
      </div>
      <div class="tabItem" @click="changeIndex(1)">
        <img v-if="currentIndex != 1" src="../assets/box.png" alt="" />
        <img v-if="currentIndex == 1" src="../assets/box1.png" alt="" />
        <p :class="currentIndex == 1 ? 'active' : ''">盲盒</p>
      </div>
      <div class="tabItem" @click="changeIndex(2)">
        <img v-if="currentIndex != 2" src="../assets/collection.png" alt="" />
        <img v-if="currentIndex == 2" src="../assets/collection1.png" alt="" />
        <p :class="currentIndex == 2 ? 'active' : ''">藏品</p>
      </div>
      <!-- <div class="tabItem" @click="changeIndex(3)">
        <img v-if="currentIndex != 3" src="../assets/exhibition.png" alt="" />
        <img v-if="currentIndex == 3" src="../assets/exhibition1.png" alt="" />
        <p :class="currentIndex == 3 ? 'active' : ''">展馆</p>
      </div> -->
      <div class="tabItem" @click="changeIndex(4)">
        <img v-if="currentIndex != 4" src="../assets/person.png" alt="" />
        <img v-if="currentIndex == 4" src="../assets/person1.png" alt="" />
        <p :class="currentIndex == 4 ? 'active' : ''">我的</p>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onUnmounted, getCurrentInstance } from "vue";

import { useStore } from "vuex";
export default {
  name: "home",
  data() {
    return {
      currentIndex: null,
    };
  },
  setup() {},
  activated() {
    switch (this.$route.path) {
      case "/index":
        this.currentIndex = 0;
        break;
      case "/blindBox":
        this.currentIndex = 1;
        break;
      case "/collection":
        this.currentIndex = 2;
        break;
      case "/person":
        this.currentIndex = 4;
        break;
    }
  },
  mounted() {
    switch (this.$route.path) {
      case "/person":
        this.currentIndex = 4;
        break;
    }
  },
  /* computed: {
    jtData() {
      return this.$store.state.currentHomeIndex;
    },
  },
  watch: {
    jtData: {
      handler(nval, oval) {
        this.currentIndex = nval;
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
  }, */

  methods: {
    changeIndex(index) {
      if (index != this.currentIndex) {
        this.currentIndex = index;
        switch (index) {
          case 0:
            this.$router.push("/index");
            break;
          case 1:
            this.$router.push("/blindBox");
            break;
          case 2:
            this.$router.push("/collection");
            break;
          case 4:
            this.$router.push("/person");
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .tab {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .tabItem {
      img {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        margin-bottom: 4px;
      }
      p {
        font-size: 12px;
        color: #bfc0cf;
      }
      p.active {
        color: #171717;
      }
    }
    .tabItem:nth-child(1) {
      margin-left: 30px;
    }
    .tabItem:last-child {
      margin-right: 30px;
    }
  }
}
</style>