<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="$route.meta.keepAlive"
      />
    </keep-alive>
    <component
      :is="Component"
      :key="$route.name"
      v-if="!$route.meta.keepAlive"
    />
  </router-view>
</template>
<script>
export default {
  provide() {
    return {
      getWxCode: this.getWxCode,
    };
  },
  data() {
    return {
      userAgent: "",
      appId: "wx02b91b34111b2a38",
      href: "",
    };
  },
  mounted() {
    this.userAgent = navigator.userAgent.toLowerCase();
  },
  methods: {
    //获取code
    getWxCode() {
      if (this.userAgent.match(/MicroMessenger/i) == "micromessenger") {
        this.href = window.location.href;
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          this.appId
        }&redirect_uri=${encodeURIComponent(
          this.href
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }
    },
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
img {
  display: block;
}
body {
  background-color: #f4f5f9;
}
input {
  outline: none;
  border: 0;
}
</style>
