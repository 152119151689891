import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function getBlindBox(obj) {
  return request({
    url: '/box/boxList',
    method: 'get',
    params: obj,
  })
}
export function getBoxDetail(obj) {
  return request({
    url: '/box/boxDetail',
    method: 'get',
    params: obj,
  })
}