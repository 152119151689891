<template>
  <div class="payTag">
    <div class="payTag-zzc" @click="closePayTag()"></div>
    <div class="payTag-div">
      <h1>请选择支付方式</h1>
      <div class="payType">
        <div class="payType-item" @click="changePayIndex(0)">
          <img src="../assets/yezf.png" alt="" class="left" />
          <p>
            余额支付<span>({{ userAccData.account }})</span>
          </p>
          <img
            v-if="payIndex != 0"
            src="../assets/selectPay1.png"
            class="right"
            alt=""
          />
          <img
            v-if="payIndex == 0"
            src="../assets/selectPay.png"
            class="right"
            alt=""
          />
        </div>
        <div class="payType-item" v-if="wxPayTF" @click="changePayIndex(1)">
          <img src="../assets/wxzf.png" alt="" class="left1" />
          <p>微信支付</p>
          <img
            v-if="payIndex != 1"
            src="../assets/selectPay1.png"
            class="right"
            alt=""
          />
          <img
            v-if="payIndex == 1"
            src="../assets/selectPay.png"
            class="right"
            alt=""
          />
        </div>
      </div>
      <div class="payBut" @click="payForm()">立即购买</div>
    </div>
  </div>
</template>
<script>
import { getUserCode, getOrder, getBlindOrder } from "../api/detail.js";
import { Toast } from "@nutui/nutui";
import wx from "weixin-js-sdk";
import { getUserInfo, getUserAcc } from "../api/person.js";

export default {
  inject: ["getWxCode"],
  props: ["goodsId", "tagType"],
  setup() {},
  data() {
    return {
      userAgent: "",
      id: "",
      goodsDetailData: {},
      timeStart: false,
      copyText: "",
      payIndex: 0,
      payTagTF: false,
      wxPayTF: false,
      userAccData: {},
      winUrl: "",
    };
  },
  mounted() {
    this.userAgent = navigator.userAgent.toLowerCase();
    console.log(this.goodsId);
    this.pay();
    if (this.getUrlParam("code")) {
      this.postCode();
    }
  },

  methods: {
    changePayIndex(index) {
      this.payIndex = index;
    },
    postCode() {
      let params = {
        code: this.getUrlParam("code"), //this.$route.query.code
      };
      getUserCode(params).then((res) => {
        console.log(res);
      });
    },
    pay() {
      let params = {};
      getUserAcc(params).then((res) => {
        this.userAccData = res.data;
        if (this.userAgent.match(/MicroMessenger/i) == "micromessenger") {
          this.wxPayTF = true;
        } else {
          this.wxPayTF = false;
        }
      });
    },
    getUserInfoData() {
      getUserInfo().then((res) => {
        if (res.data.is_wx_auth == 0) {
          this.getWxCode();
        } else {
          let params = {
            id: this.goodsId,
            pay_type: 2,
          };
          getOrder(params).then((res) => {
            console.log(res);
            if (res.code == 0) {
              Toast.text(res.msg);
            }
            if (res.code == 403) {
              this.$router.push("/realName");
            }
            if (res.code == 1) {
              wx.config({
                debug: false, //用于调试，这里一般在测试阶段先用true，等打包给后台的时候就改回false,
                appId: res.data.pay.appId,
                timestamp: res.data.pay.timeStamp,
                nonceStr: res.data.pay.nonceStr,
                signature: res.data.pay.paySign,
                jsApiList: ["chooseWXPay"], //需要使用的API，此处只使用了微信支付接口
              });
              //查看配置是否成功，成功时候才能使用微信SDK
              wx.ready(() => {
                wx.checkJsApi({
                  jsApiList: ["chooseWXPay"],
                  success: function (res) {
                    console.log("success");
                    console.log(res);
                  },
                  fail: function (res) {
                    console.log("fail");
                    console.log(res);
                  },
                });
              });
              wx.chooseWXPay({
                timestamp: res.data.pay.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res.data.pay.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.data.pay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: res.data.pay.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: res.data.pay.paySign, // 支付签名
                success: (res) => {
                  //成功回调函数
                  Toast.text("支付成功");
                  this.closePayTag();
                },

                // 支付取消回调函数
                cancel: (res) => {
                  Toast.text("用户取消支付");
                },
                // 支付失败回调函数
                fail: (res) => {
                  Toast.text("支付失败");
                },
              });
            }
          });
        }
      });
    },
    closePayTag() {
      this.$emit("getPayTag", false);
    },
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    payForm() {
      if (this.payIndex == 1) {
        this.getUserInfoData();
      } else {
        console.log("余额支付");
        let params = {
          id: this.goodsId,
          pay_type: 1,
        };
        if (this.tagType == 0) {
          getOrder(params).then((res) => {
            Toast.text(res.msg);
            if (res.code == 403) {
              this.$router.push("/realName");
            }
            if (res.code == 1) {
              this.closePayTag();
              Toast.text("支付成功");
            }
          });
        } else if (this.tagType) {
          getBlindOrder(params).then((res) => {
            Toast.text(res.msg);
            if (res.code == 403) {
              this.$router.push("/realName");
            }
            if (res.code == 1) {
              this.closePayTag();
              Toast.text("支付成功");
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.payTag {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .payTag-zzc {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  .payTag-div {
    padding-bottom: 30px;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    h1 {
      font-size: 15px;
      color: #333333;
      padding-top: 11px;
      text-align: center;
    }
    .payType {
      margin-top: 14px;
      padding: 0 22px;
      .payType-item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .left {
          width: 20px;
          margin-right: 7px;
        }
        .left1 {
          width: 22px;
          margin-right: 5px;
        }
        p {
          flex: 1;
          font-size: 14px;
          color: #666666;
          span {
            font-size: 13px;
            color: #0077d4;
          }
        }
        .right {
          width: 18px;
        }
      }
    }
    .payBut {
      width: 305px;
      height: 50px;
      background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
      border-radius: 5px;
      margin: 0 auto;
      font-size: 16px;
      color: #333333;
      text-align: center;
      line-height: 50px;
    }
  }
}
</style>