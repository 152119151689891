import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function getUserInfo(obj) {
  return request({
    url: '/user/userInfo',
    method: 'get',
    params: obj,
  })
}
export function getUserAcc(obj) {
  return request({
    url: '/account/account',
    method: 'get',
    params: obj,
  })
}

export function getUserCol(obj) {
  return request({
    url: '/orders/usersGoodsList',
    method: 'get',
    params: obj,
  })
}

export function getUserBox(obj) {
  return request({
    url: '/orders/usersBoxList',
    method: 'get',
    params: obj,
  })
}

export function getShare(obj) {
  return request({
    url: '/user/share',
    method: 'get',
    params: obj,
  })
}

export function postUserAuth(obj) {
  return request({
    url: '/user/userAuth',
    method: 'get',
    params: obj,
  })
}
export function goNft(obj) {
  return request({
    url: '/login/nft',
    method: 'get',
    params: obj,
  })
}
export function getAboutWm(obj) {
  return request({
    url: '/login/helpContent',
    method: 'get',
    params: obj,
  })
}
export function postHelp(obj) {
  return request({
    url: '/user/feedBack',
    method: 'post',
    data: obj,
  })
}
export function postImg(obj) {
  return request({
    url: '/upload/fileUpload',
    method: 'post',
    data: obj,
  })
}

export function postUser(obj) {
  return request({
    url: '/user/editUserInfo',
    method: 'post',
    data: obj,
  })
}

export function postTransfer(obj) {
  return request({
    url: '/orders/goodsTransfer',
    method: 'post',
    data: obj,
  })
}


