import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function getGoodsDetail(obj) {
  return request({
    url: '/goods/goodsDetail',
    method: 'get',
    params: obj,
  })
}

export function getCollectDetail(obj) {
  return request({
    url: '/orders/usersGoodsDetail',
    method: 'get',
    params: obj,
  })
}
export function getUserCode(obj) {
  return request({
    url: '/user/auth',
    method: 'get',
    params: obj,
  })
}
export function getOrder(obj) {
  return request({
    url: '/orders/goodsApply',
    method: 'get',
    params: obj,
  })
}
export function getBlindOrder(obj) {
  return request({
    url: '/orders/boxApply',
    method: 'get',
    params: obj,
  })
}